import React, { useEffect, useState } from "react";

import Modal from "../../../components/Feedback/Modal";
import { CalendarModalBody } from "../NewPlanning/Calendar.styles";
import { Col, Row } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import Modals from "../../../components/Feedback/Modal";
import TextAreaController from "../../../form/TextAreaController";
import InputController from "../../../form/InputController";
import Card from "../../UIElements/Card/Card.styles";
import DateTimeRangeController from "../Planning/DateTimeRangeController";
import Button from "../../../components/uielements/button";
import History from "./History";
import ShiftList from "./ShiftList";
import NewShiftModal from "./NewShiftModal";
import ConfirmPlanningModal from "./ConfirmPlanningModal";
import ForwardPlanningModal from "./ForwardPlanningModal";
import ForwardShiftModal from "./ForwardShiftModal";
import { destroy, get, SERVICES, update } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/planning/actions";
import { State } from "../../../redux/root-reducer";
import { Planning, RequestStatus, Shift } from "../../../redux/apis/types";
import {
  defaultFormPlanningDetails,
  generateConfirmShiftsData,
} from "../../../redux/apis/utils";
import { cancelPlanning, forwardPlanning } from "../../../redux/apis/planning";
import { isAdmin, isClient, isSubContractor } from "../../../constants";
import NewHistoryModal from "./NewHistoryModal";
import moment, { Moment } from "moment";
import RequestFormInfo from "./RequestFormInfo";
import BranchRateSelection from "../NewPlanning/BranchRateSelection";

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  planningId: number;
};
export default function ({ modalVisible, onClose, planningId = 1 }: Props) {
  const [saving, setSaving] = useState(false);
  const { user } = useSelector((state: State) => state.Auth);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [editShift, setEditShift] = useState<Shift | undefined>();
  const dispatch = useDispatch();
  const { planningDetails, planning } = useSelector(
    (state: State) => state.Planning
  );
  const [loading, setLoading] = useState(false);
  const [newShiftVisible, setNewShiftVisible] = useState(false);
  const [confirmPlanningVisible, setConfirmPlanningVisible] = useState(false);
  const [forwardPlanningVisible, setForwardPlanningVisible] = useState(false);
  const [forwardShiftVisible, setForwardShiftVisible] = useState(false);
  const methods = useForm();
  const { handleSubmit, reset, getValues, formState } = methods;

  async function getData() {
    setLoading(true);
    const data = await get(SERVICES.PLANNING, planningId);
    dispatch(actions.setPlanningDetails(data));
    dispatch(actions.updatePlanning(data, planningDetails.id));
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (planningDetails && planningDetails.request) {
      reset({
        ...getValues(),
        ...defaultFormPlanningDetails(planningDetails),
      });
    }
  }, [planningDetails]);

  const visible = modalVisible ? true : false;
  if (!visible) return null;

  const onDeletePlanning = () => {
    Modals.confirm({
      title: "Are you sure you want to delete this planning ?",
      content: "All shifts will be removed from this planning",
      async onOk() {
        const resp = await destroy(SERVICES.PLANNING, null, {
          planning_id: [planningDetails.id],
        });
        if (resp) {
          dispatch(
            actions.setPlanning(
              planning.filter((item) => item.id !== planningDetails.id)
            )
          );
          dispatch(actions.updatePlanning(null, planningDetails.id));
          onClose();
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const onCancelPlanning = () => {
    Modals.confirm({
      title: "Are you sure you want to cancel this planning ?",
      content: "an email will be sent to the client.",
      async onOk() {
        const resp = await cancelPlanning(planningDetails.id);
        if (resp) {
          dispatch(
            actions.updatePlanning(
              {
                ...planningDetails,
                status: RequestStatus.cancelled,
              },
              planningDetails.id
            )
          );
          onClose();
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };
  const onCancelForward = () => {
    Modals.confirm({
      title: "Are you sure you want to cancel forward this planning ?",
      content: "All shifts will be removed from this planning",
      async onOk() {
        const resp = await forwardPlanning({
          forwarded: [
            {
              subcontractor_id: null,
              planning_id: planningDetails.id,
            },
          ],
          remove_shifts: true,
        });
        if (resp) {
          const planning = await get(SERVICES.PLANNING, planningDetails.id);
          if (planning) dispatch(actions.updatePlanning(planning));
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const onEditShift = (shift: Shift) => {
    setEditShift(planningDetails?.shifts?.find((item) => item.id == shift.id));
    setNewShiftVisible(true);
  };

  const onCloseShift = () => {
    setNewShiftVisible(false);
    setEditShift(undefined);
  };

  // {
  //   start_date: Moment;
  //   end_date: Moment;
  //   start_time: string;
  //   end_time: string;
  //   note: string;
  // }
  const onEditPlanningDetails = async (data: any) => {
    const start = moment(
      `${data.start_date.format("YYYY-MM-DD")} ${data.start_time}`
    )
      .utc()
      .format("YYYY-MM-DD HH:mm");
    const end = moment(`${data.end_date.format("YYYY-MM-DD")} ${data.end_time}`)
      .utc()
      .format("YYYY-MM-DD HH:mm");

    if (isClient) {
      if (
        moment(planningDetails.start).format("YYYY-MM-DD HH:mm") !== start ||
        moment(planningDetails.end).format("YYYY-MM-DD HH:mm") !== end
      ) {
        const now = moment().utc();
        const planningStart = moment(planningDetails.start);
        if (now.add("hours", 48).isAfter(planningStart)) {
          Modals.confirm({
            title: "Too late to change the time",
            content: `You cannot change the planning time less than 48 hours from it's  starting. please contact us to confirm your modifications`,
            okText: "Ok",
          });

          reset();
          return;
        }
      }
    }
    setSaving(true);
    const resp: Planning | null = await update(
      SERVICES.PLANNING,
      planningDetails.id,
      {
        start,
        end,
        note: data.note,
        branch_rate_id: data.branch_rate_id,
        extra_hours: data.extra_hours,
        extra_branch_rate_id: data.extra_branch_rate_id,
        extra_number_of_agents: data.extra_number_of_agents,
        number_of_agents: data.number_of_agents,
      }
    );

    setSaving(false);
    getData();
    // dispatch(actions.updatePlanning(resp, planningDetails.id));
  };

  const onRefresh = () => {
    getData();
  };

  if (!planningDetails) return null;
  return (
    <div>
      <Modal
        title={"Planning Details"}
        open={visible}
        // onOk={handleSubmit(onSave)}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
        ]}
        // okText="Save"
        cancelText="Close"
        width="100%"
        style={{ top: 20 }}
      >
        <CalendarModalBody>
          <section>
            <Row
              gutter={[10, 10]}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <Col>
                  <div style={{ display: "flex" }}>
                    {planningDetails?.status === "confirmed" ||
                    planningDetails?.status === "started" ? (
                      <Button
                        onClick={() => setForwardPlanningVisible(true)}
                        type="primary"
                        style={{ marginLeft: 10 }}
                      >
                        Forward planning
                      </Button>
                    ) : (
                      <div />
                    )}

                    {!!planningDetails?.subcontractor_id && (
                      <Button
                        onClick={onCancelForward}
                        type="danger"
                        style={{ marginLeft: 10 }}
                      >
                        Cancel forward
                      </Button>
                    )}
                  </div>
                  {planningDetails?.subcontractor_id && (
                    <div style={{ fontWeight: "bold" }}>
                      {`Forwarded to ${planningDetails?.subcontractor?.name}[${planningDetails?.subcontractor_status}]`}
                    </div>
                  )}
                </Col> */}

              <Button
                loading={loading}
                style={{ marginBottom: 10 }}
                onClick={onRefresh}
                type="primary"
              >
                Refresh planning
              </Button>

              {isAdmin && (
                <Col>
                  {planningDetails?.status !== "cancelled" && (
                    <Button
                      onClick={onCancelPlanning}
                      type="warning"
                      style={{ marginRight: 10 }}
                    >
                      Cancel planning
                    </Button>
                  )}

                  <Button onClick={onDeletePlanning} type="danger">
                    Delete planning
                  </Button>
                </Col>
              )}
            </Row>
          </section>

          <Row gutter={40}>
            <Col md={6} sm={24} xs={24}>
              <Card
                title={`Planning info [${planningDetails?.request_type}]`}
                extra={`#${planningDetails?.request?.identifier}-${planningDetails?.id}`}
              >
                <FormProvider {...methods}>
                  <form>
                    <section>
                      <Row>
                        <div>
                          <b>Branch: </b>
                          {`${planningDetails?.client?.name} -> ${planningDetails?.branch?.name}`}
                        </div>
                      </Row>
                      <Row>
                        {planningDetails?.location ? (
                          <div>
                            <b>
                              {planningDetails?.location?.name}{" "}
                              {planningDetails?.location?.number}
                            </b>
                            <div>
                              {`${planningDetails?.location?.address} ${
                                planningDetails?.location?.number
                                  ? `(${planningDetails?.location?.number})`
                                  : ""
                              }`}
                            </div>
                          </div>
                        ) : (
                          "Location deleted"
                        )}
                      </Row>
                    </section>
                    <section>
                      <DateTimeRangeController disabled={isSubContractor} />
                    </section>
                    {isAdmin && (
                      <div>
                        <section>
                          <BranchRateSelection
                            disableLocation
                            name="branch_rate_id"
                            clientId={planningDetails?.client_id + ""}
                            branchId={planningDetails?.branch_id + ""}
                            locationId={planningDetails?.location_id}
                          />
                        </section>
                        <section>
                          <InputController
                            type="number"
                            label="Extra hour(s)"
                            name="extra_hours"
                          />
                          <InputController
                            type="number"
                            label="Number of agents"
                            name="extra_number_of_agents"
                          />
                          <BranchRateSelection
                            disableLocation
                            required={false}
                            name="extra_branch_rate_id"
                            clientId={planningDetails?.client_id + ""}
                            branchId={planningDetails?.branch_id + ""}
                            locationId={planningDetails?.location_id}
                          />
                        </section>
                      </div>
                    )}

                    <section>
                      <h4>Note</h4>
                      <TextAreaController
                        name="note"
                        placeholder="type your instructions here (optional)"
                      />
                    </section>

                    <Button
                      onClick={handleSubmit(onEditPlanningDetails)}
                      disabled={!formState.isDirty || saving}
                      loading={saving}
                      type="primary"
                    >
                      Save planning
                    </Button>
                  </form>
                </FormProvider>
                {planningDetails?.request && isAdmin && (
                  <RequestFormInfo request={planningDetails?.request} />
                )}
              </Card>
            </Col>
            <Col md={11} sm={24} xs={24}>
              <Card
                title="Shift(s)"
                extra={
                  isAdmin === true ? (
                    <Button
                      onClick={() => setNewShiftVisible(true)}
                      type="primary"
                      disabled={
                        !(
                          planningDetails?.status === "confirmed" ||
                          planningDetails?.status === "started"
                        )
                      }
                    >
                      Add new shift
                    </Button>
                  ) : (
                    <div />
                  )
                }
              >
                <FormProvider {...methods}>
                  {isAdmin ? (
                    <div
                      style={{
                        color: "#242b50",
                        fontWeight: "bold",
                      }}
                    >
                      <div>
                        <InputController
                          name={`number_of_agents`}
                          label="Number of agents"
                          rules={{ required: true }}
                          // style={{ width: 200 }}
                          type="numeric"
                          placeholder="Number of agents"
                        />
                      </div>
                      <Button
                        style={{ marginTop: 10 }}
                        disabled={!formState.isDirty || saving}
                        onClick={handleSubmit(onEditPlanningDetails)}
                        loading={saving}
                        type="primary"
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{ color: "#242b50", fontWeight: "bold" }}
                    >{`Number of agents per shift : ${planningDetails?.number_of_agents}`}</div>
                  )}
                </FormProvider>
                <ShiftList
                  onForwardShifts={() => setForwardShiftVisible(true)}
                  onEditShift={onEditShift}
                />
              </Card>
            </Col>
            <Col md={7} sm={24} xs={24}>
              <Card
                title="History"
                extra={
                  isAdmin && (
                    <Button
                      onClick={() => setHistoryModalVisible(true)}
                      size="small"
                      disabled={
                        !planningDetails?.shifts ||
                        planningDetails?.shifts?.length === 0
                      }
                      type="primary"
                    >
                      Add history
                    </Button>
                  )
                }
                // extra={
                //   <FormProvider {...methods}>
                //     <SelectController
                //       name={`name`}
                //       label={undefined}
                //       rules={{ required: true }}
                //       options={["Status", "Stops", "Images"]}
                //       placeholder="options"
                //     />
                //   </FormProvider>
                // }
              >
                <History />
              </Card>
            </Col>
          </Row>
          {newShiftVisible && (
            <NewShiftModal
              visible={newShiftVisible}
              onClose={onCloseShift}
              shift={editShift}
              planning={planningDetails}
            />
          )}
          {confirmPlanningVisible && (
            <ConfirmPlanningModal
              visible={confirmPlanningVisible}
              onClose={() => setConfirmPlanningVisible(false)}
            />
          )}
          {forwardPlanningVisible && (
            <ForwardPlanningModal
              visible={forwardPlanningVisible}
              onClose={() => setForwardPlanningVisible(false)}
            />
          )}
          {forwardShiftVisible && (
            <ForwardShiftModal
              visible={forwardShiftVisible}
              onClose={() => setForwardShiftVisible(false)}
            />
          )}

          {historyModalVisible && (
            <NewHistoryModal
              visible={historyModalVisible}
              onClose={() => setHistoryModalVisible(false)}
            />
          )}
        </CalendarModalBody>
      </Modal>
    </div>
  );
}
