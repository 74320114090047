import React, { useEffect, useState } from "react";
import Button from "../../../components/uielements/button";
import Timeline, {
  TimelineItem,
} from "../../../components/uielements/timeline";
import { DeleteOutlined } from "@ant-design/icons";
import { destroy, get, SERVICES } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import Modals from "../../../components/Feedback/Modal";
import HistoryItem from "./HistoryItem";
import actions from "../../../redux/planning/actions";
import NewHistoryModal from "./NewHistoryModal";
import { PlanningHistory } from "../../../redux/apis/types";
import { isAdmin, isClient } from "../../../constants";
import moment from "moment";
import { timeZoneMoment } from "../../../redux/apis/utils";
const History = () => {
  const [selectedHistory, setSelectedHistory] = useState<PlanningHistory>();
  const dispatch = useDispatch();
  const { planningDetails } = useSelector((state: State) => state.Planning);
  // useEffect(() => {
  //   async function getPlanningHistory() {
  //     const data = await get(SERVICES.PLANNING_HISTORY, planningDetails.id);
  //     if (data) {
  //     }
  //   }
  //   if (planningDetails?.status !== "pending") getPlanningHistory();
  // }, []);

  const onDeletePlanningHistory = (id: number) => {
    Modals.confirm({
      title: "Are you sure you want to delete this history ?",
      content: "You will not be able to recover it!",
      async onOk() {
        const resp = await destroy(SERVICES.PLANNING_HISTORY, id);
        if (resp) {
          dispatch(
            actions.setPlanningDetails({
              ...planningDetails,
              history: planningDetails.history.filter((item) => item.id !== id),
            })
          );
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  return (
    <Timeline>
      {planningDetails?.history &&
        planningDetails.history.map((history) => (
          <TimelineItem
            color={history.status === "ended" ? "red" : "green"}
            key={history.id}
          >
            <div>
              <HistoryItem
                history={history}
                location={planningDetails?.location}
                shift={planningDetails.shifts.find(
                  (shift) => shift.id === history.shift_id
                )}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderTopWidth: 1,
                  borderTopColor: "grey",
                  borderTop: "solid",
                  marginTop: 10,
                  paddingTop: 5,
                }}
              >
                <div style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  {timeZoneMoment(
                    history.created_at,
                    planningDetails?.location?.timezone
                  ).format("YYYY/MM/DD HH:mm")}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#242b50",
                  }}
                >
                  {history.agent
                    ? `By ${history.agent.first_name} ${history.agent.last_name}`
                    : "agent removed"}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                  gap: 5,
                }}
              >
                {isAdmin && history.status !== "image" && (
                  <Button
                    onClick={() => setSelectedHistory(history)}
                    style={{ width: "100%" }}
                    size="small"
                    type="primary"
                  >
                    Edit
                  </Button>
                )}
                {(isAdmin || (isClient && history.status === "image")) && (
                  <Button
                    onClick={() => onDeletePlanningHistory(history.id)}
                    style={{ width: "100%" }}
                    size="small"
                    type="danger"
                  >
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </TimelineItem>
        ))}
      {planningDetails?.history?.length == 0 && <div>No history available</div>}
      {selectedHistory && (
        <NewHistoryModal
          visible={!!selectedHistory}
          onClose={() => setSelectedHistory(undefined)}
          data={selectedHistory}
        />
      )}
    </Timeline>
  );
};

export default History;
