import { axiosWithToken } from "../axiosMiddleware";
import clientActions from "../clients/actions";
import requestsActions from "../requests/actions";
import contractorActions from "../contractors/actions";
import agentActions from "../agents/actions";
import planningActions from "../planning/actions";
import { isAdmin, isClient, isSubContractor } from "../../constants";
import moment from "moment";
import { Agent, User } from "./types";
import { notificationsActions } from "../notifications/actions";
import { chatActions } from "../chat/actions";
import { isImage } from "../utils";
import { AxiosError } from "axios";
export const SERVICES = {
  CLIENT: "clients",
  BRANCH: "branches",
  LOCATION: "locations",
  CONTRACTOR: "subcontractors",
  REQUEST: "requests",
  REQUEST_EQUIPMENT: "request/equipment",
  EQUIPMENT: "equipment",
  PLANNING: "planning",
  PLANNING_HISTORY: "planning/history",
  SHIFT: "shifts",
  AGENT: "agents",
  LICENSE: "licenses",
  ACCOUNT: "accounts",
  NOTIFICATION: "notifications",
  CONTACT: "contacts",
  RATE: "rates",
  HOLIDAYS: "holidays",
  INVOICE: "invoices",
};

export const fetchInitialData = async (dispatch: any, user: User) => {
  if (!isClient) {
    dispatch(chatActions.setCurrentChannel(null));
    dispatch(chatActions.getActiveChannels());
    dispatch(chatActions.getUnreadMessage());
    dispatch(notificationsActions.fetchUnreadNotificationsCount());
    dispatch(notificationsActions.fetchNotifications({ pageNumber: 1 }));
  }

  if (isAdmin) {
    const [
      // requests,
      clients,
      branches,
      locations,
      contractors,
      agents,
      equipment,
      licenses,
      holidays,
    ] = await Promise.allSettled([
      // get(SERVICES.REQUEST),
      get(SERVICES.CLIENT),
      get(SERVICES.BRANCH),
      get(SERVICES.LOCATION),
      get(SERVICES.CONTRACTOR),
      get(SERVICES.AGENT),
      get(SERVICES.EQUIPMENT),
      get(SERVICES.LICENSE),
      get(SERVICES.HOLIDAYS),
    ]);

    // if (requests.status === "fulfilled" && requests.value)
    //   dispatch(requestsActions.setRequests(requests.value));

    if (agents.status === "fulfilled" && agents.value)
      dispatch(
        agentActions.setAgents(
          agents.value.filter((agent: Agent) => !!agent.user)
        )
      );
    if (clients.status === "fulfilled" && clients.value)
      dispatch(clientActions.setClients(clients.value));
    if (branches.status === "fulfilled" && branches.value)
      dispatch(clientActions.setBranches(branches.value));
    if (locations.status === "fulfilled" && locations.value)
      dispatch(clientActions.setLocations(locations.value));

    if (contractors.status === "fulfilled" && contractors.value)
      dispatch(contractorActions.setContractors(contractors.value));

    if (equipment.status === "fulfilled" && equipment.value)
      dispatch(requestsActions.setEquipment(equipment.value));

    if (licenses.status === "fulfilled" && licenses.value)
      dispatch(agentActions.setLicenses(licenses.value));

    if (holidays.status === "fulfilled" && holidays.value)
      dispatch(planningActions.setHolidays(holidays.value));
  } else if (isSubContractor) {
    const planning = await get(SERVICES.PLANNING, 0, {
      start: moment().format("YYYY-MM-DD 00:00"),
      end: moment().add(1, "M").format("YYYY-MM-DD 23:59"),
    });
    dispatch(planningActions.setPlanning(planning));

    const agents = await get(SERVICES.AGENT);
    dispatch(
      agentActions.setAgents(agents.filter((agent: Agent) => !!agent.user))
    );
    const licenses = await get(SERVICES.LICENSE);
    dispatch(agentActions.setLicenses(licenses));
  } else if (isClient) {
    const equipment = await get(SERVICES.EQUIPMENT);
    if (equipment) dispatch(requestsActions.setEquipment(equipment));
    const requests = await get(`${SERVICES.REQUEST}/own`);
    if (requests) dispatch(requestsActions.setRequests(requests));
    const locations = await get(`${SERVICES.LOCATION}/own`);
    if (locations) dispatch(clientActions.setLocations(locations));

    if (user.entity === "client") {
      const data = await get(`${SERVICES.CLIENT}/own`);
      if (data) dispatch(clientActions.setBranches(data.branches));
    }
  }
};
export const add = async (service: string, data: any, config?: any) => {
  try {
    const response = await axiosWithToken.post(`/api/${service}`, data, config);
    return response?.data;
  } catch (e: any) {
    return e?.response?.data;
  }
};
export const get = async (
  service: string,
  id?: number | string,
  props?: any,
  config?: any
) => {
  try {
    const response = await axiosWithToken.get(
      `/api/${service}${id ? "/" + id : ""}`,
      {
        params: props,
        ...config,
      }
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const destroy = async (
  service: string,
  id: number | null,
  body?: any
) => {
  try {
    const response = await axiosWithToken.delete(
      `/api/${service}${id ? "/" + id : ""}`,
      { data: body }
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const update = async (
  service: string,
  id: number | string | null,
  data: any
) => {
  try {
    const response = await axiosWithToken.put(
      `/api/${service}${id ? "/" + id : ""}`,
      data
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const getRemoteImageURL = (url: string, fileName: string) => {
  return axiosWithToken
    .get(url, {
      responseType: "arraybuffer",
      headers: {
        Accept: "*/*",
      },
    })
    .then(async (res: any) => {
      const blob = new Blob([res.data], {
        type: isImage(fileName) ? "image/jpeg" : "application/pdf",
      });

      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    });
};
