import { Moment } from "moment";
import React from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import styled from "styled-components";
import DatePicker from "../components/uielements/datePicker";
import ErrorInputMessage from "./ErrorInputMessage";
import dayjs from "dayjs";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  style?: any;
  disabled?: boolean;
  format?: string;
  showTime?: { format: string };
  value?: Moment | null;
  disabledDate?: (date: Moment) => boolean;
};
const DatePickerController = ({
  name,
  label,
  rules,
  placeholder,
  style,
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors && errors[name];
  return (
    <div>
      {label && <h4>{label}</h4>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <div className="isoCalendarDatePicker">
              <DatePicker
                format="DD/MM/YYYY"
                style={style}
                onChange={field.onChange}
                value={dayjs(field.value)}
                {...rest}
              />
            </div>
          );
        }}
      />
      <ErrorInputMessage error={error as any} />
    </div>
  );
};

export default DatePickerController;

const ErrorMessage = styled.h5`
  color: red;
`;
