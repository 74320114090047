import { RequestStatus } from "./redux/apis/types";

export const isAdmin = process.env.REACT_APP_ROLE === "admin";
export const isClient = process.env.REACT_APP_ROLE === "client";
export const isSubContractor = process.env.REACT_APP_ROLE === "subc";

export type RequestType = {
  label: String;
};
export const REQUEST_TYPES = [
  {
    label: "Static",
  },
  {
    label: "Close protection",
  },
  {
    label: "Events",
  },
  {
    label: "Investigation",
  },
  {
    label: "K9 Security",
  },
  {
    label: "Reception",
  },
  {
    label: "Fire guard",
  },
  {
    label: "VIP Transportation",
  },
  {
    label: "Traffic Control",
  },
  {
    label: "Profiling, Observation",
  },
  {
    label: "Security Escort",
  },
  {
    label: "Red Teaming",
  },
  {
    label: "Security Awareness",
  },
  {
    label: "Risk Management",
  },
];

export const REQUEST_STATUS = [
  {
    label: "Pending (20)",
    value: RequestStatus.pending,
  },
  {
    label: "Confirmed (20)",
  },
  {
    label: "Canceled (20)",
  },
];
export const PLANNING_STATUS = [
  {
    label: "Confirmed (20)",
  },
  {
    label: "Canceled (20)",
  },
  {
    label: "Finished (20)",
  },
  {
    label: "Archived (20)",
  },
];

export const WEEKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const WEEKENDDAYS = ["Saturday", "Sunday"];
export const WORKDAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
];

export const LICENCES = [
  "security guard licence",
  "close protection licence",
  "security events  licence",
  "investigation licence",
  "security guard k9 licence",
  "fire guard licence",
];
